(function() {
  var init = function() {
    var visibilityToggles = document.getElementsByClassName(
      "visibility-toggle"
    );
    for (var i = 0; i < visibilityToggles.length; i++) {
      (function(i) {
        var toggle = visibilityToggles.item(i);
        toggle.onclick = function(event) {
          toggle.classList.toggle("open");
          var paragraph = toggle.nextElementSibling;
          paragraph.classList.toggle("is-hidden");
        };
      })(i);
    }
  };
  window.addEventListener("load", init, false);
})();
